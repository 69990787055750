.div-container{
    height: 100%;
    width: 100%;
}
.card-div{
    background-color: green;
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
}
.headerNoser{
    font-size: x-large;
    font-weight: bold;
   
    font-family: 'Courier New', Courier, monospace;
}
.whiteTxt{
    color: white;
}
.CardBig{
    width: 100%;
    height: 100%;
}
.aa{
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
}
.b{  align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;}
.aCard{
    width: 100%;
    height: 100%;
}
.SignLabel{
    font-size: x-large;
    font-weight: bolder;
    
}
.paragraph{
    font-size: small;
    font-weight: bolder;
    font-style: italic;
}
.marginL{
    margin-left: -30%;
}
.marginLL{
    margin-left: -150%;
}
.wid{
    width: 25%;
    margin-left: -10%;
}
.wid1{
    width: 18.8%;
    margin-left: -10%;
}
.wid2{
    width: 78%;
    margin-left: -10%;
}
.marginR{
    margin-right: 11%;
}
.textko{
    font-size:small;
}
.fultxt{
    margin-left: 3%;
    margin-right: 1%;
}
.login-label{
    font-size: x-large;
    font-weight: bolder;
   
}
.buttn{
   width: 28%;
}
.FORGETPASSWORD{
    justify-content: center;
    justify-self: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    align-content: center;
}
.FORGETPASSWORD2{
    justify-content: center;
    justify-self: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: center;
    margin: auto;
}
.marLLL{
    margin-left: -50%;
}
.formche{
    margin-left: 8%;
}