*{
  font-size: 10px;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-transform: uppercase !important;
}
html{
  font-size: 10px !important;
}

div{text-align: left;}
caption{display: none;}
/*body{background-image: radial-gradient(farthest-side at 1% 10%, rgb(178, 245, 189),rgb(203, 247, 244), #fff);}*/
body{
    /*background-image: url('icon/logo-1.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;*/
    font-size: 10px;
}

input, label, span, select, button, textarea, div, th, td, .card-header{
  text-transform: uppercase !important;
}
.no-text-transform{
  text-align: left !important;
  text-transform: none !important;
}
.banner-hris{
  text-align: left important;
  text-transform: none !important;
}

.navbar{
  /*background-color: #7B02EC;*/
  /*background-image: linear-gradient(to right,#7B02EC , #7C06B9);*/
  background: transparent url("./icon/nav-gradient.jpg") repeat-x scroll 0% 0%;
  /*box-shadow: 0px 3px rgba(2, 99, 7, 0.589);*/
  border-bottom: 2pt solid rgba(1, 43, 3, 0.589);
}
.navbar-brand{
  color:#fff !important;
  font-weight: bold;
  text-shadow: 2px 2px 4px #000000;
}
.nav-link{
  color:#fff !important;
  text-align: left;
  align-items: flex-end;
  font-weight: bold;
  text-shadow: 2px 2px 4px #000000;
}
  /*.navbar-nav a:hover {
    color: #4f782d !important;
  }*/
.nav-link.active,
.nav-item.show .nav-link {
  /*background:rgba(0,0,0,0.5);*/
  background:rgb(8, 139, 30);
  cursor: pointer;
}
nav .dropdown-menu{
  opacity: 0.9;
  /*background:rgba(0,0,0,0.5);*/
  background:rgb(8, 139, 30) !important;
  opacity: 0.8;
  filter: alpha(opacity=30);
  text-align: left;
  font-weight: bold;
  margin-top: -1px !important;
  border-radius: 0 !important;
  width: 250px;
  cursor: pointer;
}
nav .dropdown-menu.show{
  opacity: 0.9;
  /*background:rgba(0,0,0,0.5);*/
  background:rgb(8, 139, 30);
  opacity: 0.8;
  filter: alpha(opacity=30);
  text-align: left;
  font-weight: bold;
  margin-top: -1px !important;
  border-radius: 0 !important;
  width: 250px;
  /* cursor: pointer; */
}
nav .dropdown-menu a{
  text-align: left;
  color:white !important; 
  font-weight: bold;
  text-shadow: 4px 4px 10px #000000;
  /* cursor: pointer; */
}
nav .dropdown-menu a:hover{
  color: #203409!important;
  font-weight: bold;
  /* cursor: pointer; */
}
/*TABS*/
legend{
  font-size: 12px !important;
}
.tab-content{
    color:#000;
    min-height: 100px;
    margin : 16px 0 0 -8px;
    /*border: .5pt solid rgba(17, 99, 31, 0.644) !important;*/
    /*padding: 4px;*/
}
.nav-tabs a:hover{
  /*background-color: rgb(8, 139, 30) !important;*/
    border-radius: 1pt solid rgb(8, 139, 30) !important;
    color:#000;
}

.nav-tabs > .nav-link{
  border-radius: 1pt solid rgb(8, 139, 30) !important;
  background-color: rgb(3, 77, 15) !important;
  margin: 0 0 0 1px;
}
.nav-link.active {
  border-radius: 1pt solid rgb(8, 139, 30) !important;
  background-color: rgb(8, 139, 30) !important;
  box-shadow: 0 6px rgb(8, 139, 30);
}
.nav-link-no-css.active {
  text-transform: uppercase;
}
/*MODAL FOR LOADING*/
.noser-modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
  
.noser-modal-main {
  position:fixed;
  background: transparent;
  width: 25%;
  min-width: 125px;
  height: 10%;
  top:50%;
  left:50%;
  border-radius: 0.5em;
  transform: translate(-50%,-50%);
  z-index: 80;
  opacity: 0.9;
  filter: alpha(opacity=70);
  background-color: #fff;
  border: 1pt solid #333;
  /*box-shadow: 10px 10px #333;
  border: 1px solid #203409;*/
}
.noser-img-loader-conatianer{
  width: 24px;
  height: 24px;
  margin: 8px auto 0 auto;
  
}
.noser-modal-main h6{
  font-size: 14px;
  color: #0F6016;
  font-weight: bold;
  text-align: center;
  margin: 0px 0 0 0;
  text-transform: none !important;
}
.noser-img-loader-conatianer img{
  display: block;
  margin: 0 auto;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}

/*UnAuthorized*/
.noser-unathorized{
  background-image: url("./icon/401.png");
  background-repeat: no-repeat;
  width: 664px;
  height: 481px;
  margin: 120px auto 0 auto;
}
.table {
  overflow: auto;
  display: block;
  table-layout: auto;
  padding: 0 !important;
  /*min-height: 400px;*/
  border: 1pt solid rgb(177, 173, 173) !important;
}
.table tr td, .dx-row, .dx-data-row{
  padding-top: 2px!important;
  padding-bottom: 2px!important;
  cursor: pointer;
  /*white-space: nowrap!important;*/
}
.table th, .dx-header-row{
  padding-top: 2px!important;
  padding-bottom: 2px!important;
  /*background-color: #28A745!important;*/
  background-color:  rgb(8, 139, 30);
  color:#fff !important;
  white-space: normal;
  border: 1pt solid #DEE2E6 !important;
  /*white-space: nowrap !important;*/
}
/*.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color:#E9ECEF;
 }*/
.modal-title{
  font-size: 12px !important;
  text-shadow: 0 2px 5px 0 rgb(0, 0, 0,0.2),
              0 2px 10px 0 rgb(0, 0, 0, .1) !important;
}
.modal-body{
  padding-top: 20px !important;
}
.noser-button-mr1{
  min-width: 60px !important;
  margin-right: 1px;
}
.noser-button-mr15{
  min-width: 60px !important;
  margin-right: 15pt;
}
.noser-button{
  min-width: 60px !important;
}

.btn-success{
  background-color: rgb(8, 139, 30) !important;
}
.btn-success, .btn-primary, .btn-danger{
  box-shadow: 0 2px 5px 0 rgb(0, 0, 0,0.2),
              0 2px 10px 0 rgb(0, 0, 0, .1);
              
  /*min-width: 60px !important;*/
}
/*.btn-group button{
  width: 28.2px !important;
  height: 25.16px !important;
}*/

.card{
  border: .5pt solid rgba(17, 99, 31, 0.644) !important;
  font-size: 10px !important;
}
.card-login{
  border: none !important;
  background-color: transparent !important;
}
.card-header{
  background-image: linear-gradient(to right,rgba(13, 88, 17, 0.986) , rgb(8, 139, 30));
  /*color: rgba(13, 88, 17, 0.986);*/
  color: #fff;
  text-shadow: 4px 4px 10px #000;
  font-weight: bold;
  /*border: 2pt solid rgba(13, 88, 17, 0.986) !important;*/
  border: none !important;
  min-height: 20px;
}
.card-footer{
  text-align: right;
  font-size: 11px;
  color: rgba(13, 88, 17, 0.986);
  text-shadow: 4px 4px 10px #000;
  font-weight: bold;
}
.card-tab{border-top: none !important;margin: .6pt 0 0 1pt;border-radius:none !important ;}
.card-tab-no-border{border: none !important;margin: .6pt 0 0 2pt;}
.card-header-tab{
  background-color: rgb(8, 139, 30) !important;
  color: #fff;
  text-shadow: 4px 4px 10px #000;
  font-weight: bold;
  border: none !important;
  min-height: 20px;
  border: none !important;
  border-radius:none !important ;
}
.card-accordion-header{
  background-color: rgb(8, 139, 30) !important;
  color: #fff;
  text-shadow: 4px 4px 10px #000;
  font-weight: bold;
  border: none !important;
  height: 20px !important;
  border: none !important;
  border-radius:none !important ;
}
.react-bootstrap-table-pagination-list {
  
  background-color: transparent;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
}
.react-bootstrap-table-pagination{
  background: transparent;
}

.alert-danger, .alert-warning, .alert-success, .alert-info{
  padding: 6px !important;
}
.alert-danger{background: rgb(235,204,209);background: linear-gradient(0deg, rgba(235,204,209,1) 0%, rgba(235,204,221,0.927608543417367) 100%);}

@media screen and (min-width:600px){
  /*style={{marginTop:'10%',textAlign:'left'}}*/
  .login-frm{
    text-align: left;
    width: 700px !important;
    margin: 18% auto 0 auto;
  }
  /* .noser-home{
    background-image: url('icon/logo-0.png');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-position: center;
     width: 100%;
 
   } */
  body{
    width: 100%;
    background-image: url('icon/bgcorner.png');
    background-position: top left;
    background-repeat: no-repeat;
  }
}
@media (max-width:576px) {
  .lg-button { width: 100% !important;display: block !important;}  
  .card{margin-top: 45%;}
  .noser-home{
    width: 100%;
    background-image: url('icon/noserlogo.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }
  body{
    width: 100%;
    background-image: url('icon/bgcornersmall.png');
    background-position: top left;
    background-repeat: no-repeat;
  }
}
.noser-home{
  /*background-image: url("./icon/home.png");*/
  background-image: url('icon/noserlogo.png');
  background-repeat: no-repeat;
  /*background-attachment: fixed;
  background-position: center;*/
  width: 582px;
  height: 118px;
  margin: 18% auto 0 auto;
  /*border: 1pt solid #036003;
  position: relative;
  box-shadow: 6px 6px #666;
  border-radius: 0.5em;*/
}
.noser-home span{
position: absolute;
font-size: 12px;
font-weight: bold;
 bottom: 0;
 right: 6px;
}
.react-bootstrap-table-pagination-list{
  margin-left: auto;
}

/*alert type*/
.alert-success, .alert-danger, .alert-info, .alert-warning{
  font-weight: bold;
  text-transform: none !important;
}
.success, .danger, .info, .warning{
  width: 16px;
  height: 16px;
  margin-right: 4px;
  float: left;
}
.success{
  background-image: url('./icon/16/success.png');
  background-repeat: no-repeat;
}
.danger{
  background-image: url('./icon/16/danger.png');
  background-repeat: no-repeat;
}
.info{
  background-image: url('./icon/16/info.png');
  background-repeat: no-repeat;
}
.warning{
  background-image: url('./icon/16/warning.png');
  background-repeat: no-repeat;
}
.expand-cell{
  cursor: pointer;
}
.glyphicon-minus{
  width: 14px;
  height: 16px;
}

.glyphicon-plus{
  width: 14px;
  height: 16px;
}

.form-check-label{
  padding-left: 4px;
  padding-top: 2px;
}
.form-check input[type=checkbox]{
  margin:3px 0px 0px -12.5px !important;
  background-color: green;
}
.form-check input[type=radio]{
  margin:1px -2px 0px 0px !important;
}

.rc-time-picker{font-size: 10px;}

.role-access-header-class{
  display: none !important;
}

.expandable-table{
  padding: 0 !important;
  margin: 0 0 0 22px;
}
.react-bootstrap-table .row-expansion-style, .react-bootstrap-table .table-responsive
{
  padding: 0 !important;
  margin: 0;
}
.row-expand-slide-enter-done
{
  padding: 0 !important;
  margin: 0 !important;
}
.row-expand-slide-enter-done
{
  padding: 0 !important;
  margin: 0 !important;
}

.noser-error-handler{
  height: 30%;
  width: 40%;
  margin: 20% auto 0 auto;
}
.noser-error-handler h1, details, summary, p, div{
  text-transform: none !important;
  white-space: nowrap;
}
.noser-error-handler p{
  font-weight: bold;
  color :rgba(29, 68, 245, 0.589);
}
.noser-error-handler details:focus{
  border: none;
}
.noser-error-handler h1{
  font-size: 20px;
  font-weight: bold;
  color: red;
  text-shadow: 1px 2px 4px #000;
  /*color: #fff;
  text-shadow: 1px 1px 2px #000000;
  */
}
.modal-header{
  background-image: linear-gradient(to right,rgba(13, 88, 17, 0.986) , rgb(8, 139, 30));
  /*color: rgba(13, 88, 17, 0.986);*/
  color: #fff;
  text-shadow: 4px 4px 10px #000;
  font-weight: normal !important;
  /*border: 2pt solid rgba(13, 88, 17, 0.986) !important;*/
  border: none !important;
  height: 30px !important;
  padding: 7px 0 0 10px !important;
  
}
.modal-header .close{
  width: 30px;
  height: 30px;
  margin: -9px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
}
.modal-title{
  font-size: 10px !important;
  font-weight: bold !important;
}
.modal-title{
  font-size: 10px !important;
  text-shadow: 0 2px 5px 0 rgb(0, 0, 0,0.2),
              0 2px 10px 0 rgb(0, 0, 0, .1) !important;
}
.modal-body{
  padding-top: 20px !important;
}
.no-checkbox input[type=checkbox]{
  display:none;
}
legend.w-auto{
  font-size: 10px !important;
  font-weight: bold;
  color:rgb(8, 139, 30);
}
.no-checkbox input[type=checkbox]{
  display:none;
}

.rc-time-picker-input{
  height: 24px !important;
  width: 70px !important;
}

.noser-form-label{
  width: 300px !important;
  background-color: navy;
}
.form-control-file {
  border: none !important;
  background-color: white !important;
}
.noser-a4-body-page{
  background: #fff;
}
div.noser-a4-page{
  text-transform: none !important;
  margin-left: 35mm;
  margin-right: 35mm;
  background: #fff;
  text-align: justify;
}
.no-text-transform-li{
  text-transform: none !important;
}

.waviy {
  margin: auto;
  width: 90px;
}
.waviy span {
  position: inherit;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: none !important;
  animation: flip 3s infinite;
  animation-delay: calc(.3s * var(--i));
  text-align: center;
}
@keyframes flip {
  0%,80% {
    transform: rotateY(360deg) 
  }
}
.flipY {
  animation-name: spin, depth;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 6s;
}
@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-360deg);
  }
}
@keyframes depth {
  0% {
    text-shadow: 0 0 #fff;
  }
  25% {
    text-shadow: 1px 0 #fff, 2px 0 #fff, 3px 0 #fff, 4px 0 #fff, 5px 0 #fff;
  }
  50% {
    text-shadow: 0 0 #fff;
  }
  75% {
    text-shadow: -1px 0 #fff, -2px 0 #fff, -3px 0 #fff, -4px 0 #fff,
      -5px 0 #fff;
  }
  100% {
    text-shadow: 0 0 #fff;
  }
}
